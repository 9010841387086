import React from "react";
import { Link } from "react-router-dom";
import SigninModal from "./SigninModal";
import SettingsButton from "./SettingsButton";
import { TypeAnimation } from "react-type-animation";

const NavBar = ({ user, setUser, signinModalOpen, setSigninModalOpen, searchable }) => {
  return (
    <nav className={`w-full flex-shrink-0 md:flex justify-center z-30 py-3 p-3 bg-white`}>
      <SigninModal open={signinModalOpen} setOpen={setSigninModalOpen} />
      <div className="flex max-w-screen-xl w-full h-full justify-between space-x-2">
        <div className="flex space-x-6 w-full items-center">
          <Link to="/" className="flex-shrink-0 font-semibold">
            <TypeAnimation sequence={["Raghut"]} speed={50} style={{ fontSize: "1.3rem" }} />
          </Link>
        </div>

        <div className="flex flex-shrink-0 font-medium space-x-2">
          {user ? (
            <SettingsButton user={user} setUser={setUser} />
          ) : (
            <button
              onClick={() => setSigninModalOpen(true)}
              className="text-sm md:text:base bg-white border border-gray-500 h-9 px-3 flex-shrink-0"
            >
              Sign in
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
