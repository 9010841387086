import React, { useCallback, useEffect, useState } from "react";
import ScrollToTop from "./utils/scrollToTop";
import NavBar from "./components/NavBar";
import axios from "axios";

import AdminPage from "./pages/AdminPage";

function App() {
  const [signinModalOpen, setSigninModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAuthenticatedUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/auth/success`, {
        withCredentials: true,
      });
      if (response.data.success) {
        setUser(response.data.user);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAuthenticatedUser();
  }, [fetchAuthenticatedUser]);

  if (isLoading) {
    return <div className="h-screen w-full flex items-center justify-center text-gray-500">Loading...</div>;
  }

  return (
    <div className="font-space-grotesk">
      <ScrollToTop />
      <NavBar
        user={user}
        setUser={setUser}
        setSigninModalOpen={setSigninModalOpen}
        signinModalOpen={signinModalOpen}
        searchable={true}
      />
      <div
        style={{ minHeight: "calc(100svh - 3.8rem)" }}
        className="flex flex-col items-center w-full overflow-x-hidden h-full"
      >
        <AdminPage user={user} />
      </div>
    </div>
  );
}

export default App;
