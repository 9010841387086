import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const AdminPage = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [searchLogs, setSearchLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSearches, setTotalSearches] = useState(0);
  const [searchesInLast24Hours, setSearchesInLast24Hours] = useState(0);
  const [apiKeyRequests, setApiKeyRequests] = useState({
    overview: { totalRequests: 0, last24Hours: 0, last7Days: 0, last30Days: 0 },
    byPlan: {},
    byStatus: {},
    recentRequests: [],
  });

  const [searchParams, setSearchParams] = useSearchParams();

  // Memoize admin IDs
  const adminIds = useMemo(
    () => [
      "6717001dd8156c68024973a9", // Andrew Jang (dhj9817@gmail.com)
      "6717001ed8156c68024973ae", // Andrew Jang (andrew@raghut.com)
      "6717b1fb6025dff18c721f33", // William Won (william@raghut.com)
    ],
    []
  );

  const currentTab = searchParams.get("tab") || "users";

  // Fetch users if the tab is 'users'
  useEffect(() => {
    if (user && adminIds.includes(user._id) && currentTab === "users") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/user/users`, { withCredentials: true })
        .then((response) => {
          const sortedUsers = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setUsers(sortedUsers);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    }
  }, [user, adminIds, currentTab]);

  // Fetch projects if the tab is 'projects'
  useEffect(() => {
    if (user && adminIds.includes(user._id) && currentTab === "projects") {
      const page = parseInt(searchParams.get("page")) || 1;
      setCurrentPage(page);

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/projects/new-to-old`, {
          params: { page, limit: 30 },
          withCredentials: true,
        })
        .then((response) => {
          setProjects(response.data.tools);
          setTotalPages(response.data.totalPages); // Set total pages from API response
        })
        .catch((error) => {
          console.error("Error fetching projects:", error);
        });
    }
  }, [user, adminIds, currentTab, searchParams]);

  // Fetch search logs if the tab is 'search-logs'
  useEffect(() => {
    if (user && adminIds.includes(user._id) && currentTab === "search-logs") {
      const page = parseInt(searchParams.get("page")) || 1;
      setCurrentPage(page);

      axios
        .get(`${process.env.REACT_APP_API_URL}/api/analytics/search-logs`, {
          params: { limit: 30, page },
          withCredentials: true,
        })
        .then((response) => {
          const { logs, totalSearches, searchesInLast24Hours, totalCount } = response.data;
          setSearchLogs(logs || []); // Fallback to an empty array if data is undefined
          setTotalSearches(totalSearches);
          setSearchesInLast24Hours(searchesInLast24Hours);
          setTotalPages(Math.ceil(totalCount / 30));
        })
        .catch((error) => {
          console.error("Error fetching search logs:", error);
        });
    }
  }, [user, adminIds, currentTab, searchParams]);

  // Add new useEffect for fetching API key analytics
  useEffect(() => {
    if (user && adminIds.includes(user._id) && currentTab === "api-requests") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/analytics/api-key-requests/analytics`, {
          withCredentials: true,
        })
        .then((response) => {
          setApiKeyRequests(response.data);
          console.log(response.data)
        })
        .catch((error) => {
          console.error("Error fetching API key requests:", error);
        });
    }
  }, [user, adminIds, currentTab]);

  const changeTab = (tab) => {
    setSearchParams({ tab });
  };

  const changePage = (page) => {
    setSearchParams({ tab: currentTab, page });
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const logTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - logTime) / 1000);

    const timeMappings = [
      { unit: "year", seconds: 60 * 60 * 24 * 365 },
      { unit: "month", seconds: 60 * 60 * 24 * 30 },
      { unit: "day", seconds: 60 * 60 * 24 },
      { unit: "hour", seconds: 60 * 60 },
      { unit: "minute", seconds: 60 },
      { unit: "second", seconds: 1 },
    ];

    for (const { unit, seconds } of timeMappings) {
      const count = Math.floor(diffInSeconds / seconds);
      if (count > 0) {
        return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
      }
    }
    return "just now";
  };

  if (!user || !adminIds.includes(user._id)) {
    return <div>You are not authorized to access this page.</div>;
  }

  return (
    <div className="w-full px-6 py-4">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="flex space-x-3 mb-6">
          <button
            onClick={() => changeTab("users")}
            className={`px-2 py-1 text-xs rounded-full ${
              currentTab === "users" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Users
          </button>
          <button
            onClick={() => changeTab("projects")}
            className={`px-2 py-1 text-xs rounded-full ${
              currentTab === "projects" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Projects
          </button>
          <button
            onClick={() => changeTab("search-logs")}
            className={`px-2 py-1 text-xs rounded-full ${
              currentTab === "search-logs" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            Search Logs
          </button>
          <button
            onClick={() => changeTab("api-requests")}
            className={`px-2 py-1 text-xs rounded-full ${
              currentTab === "api-requests" ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            API Requests
          </button>
        </div>

        {currentTab === "users" && (
          <div>
            <h2 className="text-lg font-medium mb-4">Users ({users.length})</h2>
            <ul>
              {users.map((u) => (
                <li key={u._id} className="pb-1 mb-1 border-b">
                  <div className="flex justify-between">
                    <div className="text-xs">
                      [{getTimeAgo(u.createdAt)}] <span>{u.displayName}</span> ({u.email})
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {currentTab === "projects" && (
          <div>
            <h2 className="text-lg font-medium mb-4">
              Projects (Page {currentPage} of {totalPages})
            </h2>
            <ul>
              {projects.map((p) => (
                <li key={p._id} className="pb-1 mb-1 border-b">
                  <div className="flex justify-between">
                    <div className="text-xs">
                      <p>
                        [{getTimeAgo(p.createdAt)}] <span className="font-semibold">{p.name}</span> {p._id}
                      </p>
                      <p>{p.website}</p>
                    </div>
                    <div className="text-gray-500 text-sm"></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {currentTab === "search-logs" && (
          <div>
            <div className="mb-4 space-y-2">
              <p className="text-sm">
                <strong>Total Searches:</strong> {totalSearches}
              </p>
              <p className="text-sm">
                <strong>Searches in the Last 24 Hours:</strong> {searchesInLast24Hours}
              </p>
            </div>
            <ul>
              {searchLogs.map((log) => (
                <li key={log._id} className="pb-1 mb-1 border-b">
                  <div className="flex justify-between">
                    <div className="text-xs space-y-1">
                      <p>
                        [{getTimeAgo(log.timestamp)}]{" "}
                        <span className="font-semibold">
                          {typeof log.query === "object" ? JSON.stringify(log.query) : log.query}
                        </span>
                      </p>
                      <p>
                        {log.userId && `${log.displayName} (${log.userId})`} {log.ipAddress || "N/A"}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {currentTab === "api-requests" && (
          <div>
            <div className="flex flex-wrap">
              <div className="bg-white p-4 rounded-lg">
                <h3 className="text-xs font-medium text-gray-500">Total Requests</h3>
                <p className="text-base font-semibold mt-1">{apiKeyRequests.overview.totalRequests}</p>
              </div>
              <div className="bg-white p-4 rounded-lg">
                <h3 className="text-xs font-medium text-gray-500">Last 24 Hours</h3>
                <p className="text-base font-semibold mt-1">{apiKeyRequests.overview.last24Hours}</p>
              </div>
            </div>

            <div className="bg-white rounded-lg p-4">
              <div className="space-y-2">
                {apiKeyRequests.recentRequests.map((request) => (
                  <div key={request.id} className="border-b border-gray-100 pb-4 last:border-0 last:pb-0">
                    <div className="flex justify-between items-start">
                      <div className="space-y-1">
                        <p className="font-medium">{request.name}</p>
                        <p className="text-sm text-gray-600">{request.email}</p>
                        <div className="flex gap-2 text-sm text-gray-500">
                          <span>{request.plan}</span>
                        </div>
                        {request.useCase && (
                          <p className="text-sm text-gray-600 mt-2">
                            <span className="font-medium">Use Case:</span> {request.useCase}
                          </p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="text-xs text-gray-500 mt-1">{getTimeAgo(request.timestamp)}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between mt-4">
          <button
            disabled={currentPage <= 1}
            onClick={() => changePage(currentPage - 1)}
            className={`px-4 py-2 ${currentPage <= 1 ? "bg-gray-300" : "bg-blue-500 text-white"}`}
          >
            Previous
          </button>
          <button
            disabled={currentPage >= totalPages}
            onClick={() => changePage(currentPage + 1)}
            className={`px-4 py-2 ${currentPage >= totalPages ? "bg-gray-300" : "bg-blue-500 text-white"}`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
